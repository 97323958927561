import React from 'react';
import { createRoot } from 'react-dom/client';
import 'scripts/favicon-switcher';

import Schedule from 'components/schedule/Schedule';

// biome-ignore lint/suspicious/noExplicitAny: any is used for dynamic component loading
const componentMap: Record<string, React.ComponentType<any>> = {
  Schedule,
};

for (const el of document.getElementsByClassName('react-mount')) {
  if (!(el instanceof HTMLElement)) {
    continue;
  }
  const component = el.getAttribute('data-component');
  if (!component) {
    console.error('Missing data-component');
    continue;
  }
  const propsJson = el.getAttribute('data-props');
  const props = JSON.parse(propsJson || '{}');
  const Component = componentMap[component];
  if (!Component) {
    throw new Error(`Unknown component ${component}`);
  }
  createRoot(el).render(
    <React.StrictMode>
      <Component {...props} />
    </React.StrictMode>,
  );
}
