import { zeroPad } from './helpers';
import type { AnyScheduleItem } from './types';
import fallbackImage from '../../../assets/images/fallback/landscape_default.png';

interface Props {
  offsetTop: number;
  height: number;
  item: AnyScheduleItem;
  pixelsPerHour: number;
}

const ScheduleDayItem = (props: Props): React.ReactElement => {
  const { offsetTop, item, height } = props;

  const backgroundStyle: React.CSSProperties = {
    backgroundImage: `url(${item.cover_image ?? fallbackImage})`,
  };
  const background = (
    <div className="background-image" style={backgroundStyle} />
  );

  const timestamp = (
    <div className="start-time">
      {zeroPad(item.hour, 2)}:{zeroPad(item.minute, 2)}
    </div>
  );
  return (
    <div className="item" style={{ top: offsetTop, height }}>
      {background}
      {timestamp}
      <a className="label-box-container" href={item.link_to}>
        <div className="label-box">
          <span>{item.title}</span>
        </div>
      </a>
    </div>
  );
};

export default ScheduleDayItem;
