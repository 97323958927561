import { uniq } from 'lodash';
import type { AnyScheduleItem } from './types';

export function extractScheduleLocations(
  items: Array<AnyScheduleItem>,
): Array<string> {
  return uniq(items.map(item => item.location));
}

export function toAdjustedHour(hour: number): number {
  return hour < 6 ? hour + 24 : hour;
}

export function fromAdjustedHour(hour: number): number {
  return hour >= 24 ? hour - 24 : hour;
}

export function calculateFirstAndLastHours(items: Array<AnyScheduleItem>) {
  const firstHourAdjusted = Math.min(
    ...items.map(item => toAdjustedHour(item.hour)),
  );
  const lastHourAdjusted = Math.ceil(
    Math.max(
      ...items.map(
        item => toAdjustedHour(item.hour) + item.minute / 60 + item.length / 60,
      ),
    ),
  );
  if (lastHourAdjusted === 25) {
    console.log(items);
  }
  return { firstHourAdjusted, lastHourAdjusted };
}

export function zeroPad(num: number, size: number) {
  let s = String(num);
  while (s.length < (size || 2)) {
    s = `0${s}`;
  }
  return s;
}
