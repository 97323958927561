import ScheduleDay from './ScheduleDay';
import type { ScheduleDayProps } from './types';

interface Props {
  days: Array<ScheduleDayProps>;
  pixels_per_hour?: number;
  show_day_labels?: boolean;
}

const Schedule = (props: Props): React.ReactElement => {
  const { days, pixels_per_hour = 120, show_day_labels = true } = props;

  return (
    <div className="schedule">
      {days.map(day => (
        <ScheduleDay
          key={day.date}
          day={day}
          pixelsPerHour={pixels_per_hour}
          showDayLabels={show_day_labels}
        />
      ))}
    </div>
  );
};

export default Schedule;
