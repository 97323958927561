const lightSchemeIcon = document.querySelector(
  'link#light-mode-favicon',
) as HTMLLinkElement;
const darkSchemeIcon = document.querySelector(
  'link#dark-mode-favicon',
) as HTMLLinkElement;

const mediaQuerySupportsEventTargetMethods =
  Object.getPrototypeOf(window.matchMedia('')) instanceof EventTarget;

type MediaQueryChangeEventListener = (event: MediaQueryListEvent) => unknown;
const addMediaQueryListener = mediaQuerySupportsEventTargetMethods
  ? (mediaQuery: MediaQueryList, listener: MediaQueryChangeEventListener) =>
      mediaQuery.addEventListener('change', listener)
  : (mediaQuery: MediaQueryList, listener: MediaQueryChangeEventListener) =>
      mediaQuery.addListener(listener);

const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
addMediaQueryListener(isDarkMode, onUpdate);
onUpdate();

function onUpdate() {
  if (isDarkMode.matches) {
    lightSchemeIcon.remove();
    document.head.appendChild(darkSchemeIcon);
  } else {
    document.head.appendChild(lightSchemeIcon);
    darkSchemeIcon.remove();
  }
}

export type {};
