import { useCallback, useMemo } from 'react';
import { toAdjustedHour } from './helpers';
import ScheduleDayItem from './ScheduleDayItem';
import type { AnyScheduleItem } from './types';

interface Props {
  name: string;
  firstHourAdjusted: number;
  lastHourAdjusted: number;
  items: Array<AnyScheduleItem>;
  pixelsPerHour: number;
  topOffsetPx: number;
  bottomOffsetPx: number;
}

const ScheduleDayColumn = (props: Props): React.ReactElement => {
  const {
    name,
    firstHourAdjusted,
    lastHourAdjusted,
    items,
    pixelsPerHour,
    topOffsetPx,
    bottomOffsetPx,
  } = props;

  const numHours = lastHourAdjusted - firstHourAdjusted;
  const height = numHours * pixelsPerHour + topOffsetPx + bottomOffsetPx;

  const calculateItemOffset = useCallback(
    (item: AnyScheduleItem): number => {
      const offsetHour =
        toAdjustedHour(item.hour + item.minute / 60) - firstHourAdjusted;
      return offsetHour * pixelsPerHour + topOffsetPx;
    },
    [firstHourAdjusted, pixelsPerHour, topOffsetPx],
  );
  const calculateItemHeight = useCallback(
    (item: AnyScheduleItem): number => (item.length / 60) * pixelsPerHour,
    [pixelsPerHour],
  );

  const verticallyLayouted = useMemo(
    () =>
      items.map(item => {
        const top = calculateItemOffset(item);
        const height = calculateItemHeight(item);
        return {
          source: item,
          top,
          height,
          bottom: top + height,
        };
      }),
    [items, calculateItemOffset, calculateItemHeight],
  );

  const lanes = useMemo(() => {
    const lanes: Array<Array<(typeof verticallyLayouted)[0]>> = [];
    for (const item of verticallyLayouted) {
      const lane = lanes.find(lane => {
        return lane.every(otherItem => {
          return (
            item.bottom < otherItem.top + 1 || item.top + 1 > otherItem.bottom
          );
        });
      });
      if (lane) {
        lane.push(item);
      } else {
        lanes.push([item]);
      }
    }
    return lanes;
  }, [verticallyLayouted]);
  const numLanes = lanes.length;
  const columnWidth = 200 * numLanes;

  return (
    <div className="column" style={{ minWidth: columnWidth }}>
      <div className="column-label">{name}</div>
      <div className="column-content" style={{ height }}>
        {lanes.map((lane, index) => (
          <div className="column-lane" key={index} style={{ height }}>
            {lane.map((item, index) => (
              <ScheduleDayItem
                key={index}
                pixelsPerHour={pixelsPerHour}
                offsetTop={item.top}
                height={item.height}
                item={item.source}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleDayColumn;
