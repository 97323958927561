import { groupBy } from 'lodash';
import { useMemo } from 'react';
import {
  calculateFirstAndLastHours,
  extractScheduleLocations,
} from './helpers';
import ScheduleDayColumn from './ScheduleDayColumn';
import ScheduleDayTimestamps from './ScheduleDayTimestamps';
import type { ScheduleDayProps } from './types';

interface Props {
  day: ScheduleDayProps;
  pixelsPerHour: number;
  showDayLabels: boolean;
}

const TOP_OFFSET_PX = 2;
const BOTTOM_OFFSET_PX = 2;

const ScheduleDay = (props: Props): React.ReactElement => {
  const { day, pixelsPerHour, showDayLabels } = props;
  const { items, day_name } = day;

  const { firstHourAdjusted, lastHourAdjusted } = useMemo(
    () => calculateFirstAndLastHours(items),
    [items],
  );
  const itemsByLocation = useMemo(
    () => groupBy(items, item => item.location),
    [items],
  );
  const locations = useMemo(() => extractScheduleLocations(items), [items]);

  return (
    <div className="schedule-day">
      <div className="day-label">{showDayLabels && day_name}</div>
      <div className="day-content">
        <ScheduleDayTimestamps
          firstHourAdjusted={firstHourAdjusted}
          lastHourAdjusted={lastHourAdjusted}
          pixelsPerHour={pixelsPerHour}
          topOffsetPx={TOP_OFFSET_PX}
        />
        <div className="columns-container">
          <div className="columns">
            {locations.map(location => (
              <ScheduleDayColumn
                key={location}
                name={location}
                firstHourAdjusted={firstHourAdjusted}
                lastHourAdjusted={lastHourAdjusted}
                items={itemsByLocation[location] ?? []}
                pixelsPerHour={pixelsPerHour}
                topOffsetPx={TOP_OFFSET_PX}
                bottomOffsetPx={BOTTOM_OFFSET_PX}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleDay;
