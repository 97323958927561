import { range } from 'lodash';
import { fromAdjustedHour, zeroPad } from './helpers';

interface Props {
  firstHourAdjusted: number;
  lastHourAdjusted: number;
  pixelsPerHour: number;
  topOffsetPx: number;
}

const ScheduleDayTimestamps = (props: Props): React.ReactElement => {
  const { firstHourAdjusted, lastHourAdjusted, pixelsPerHour, topOffsetPx } =
    props;

  const numHours = lastHourAdjusted - firstHourAdjusted;
  const height = numHours * pixelsPerHour + topOffsetPx;

  const timestamps = range(firstHourAdjusted, lastHourAdjusted).map(hour => (
    <div key={hour} className="timestamp" style={{ height: pixelsPerHour }}>
      {zeroPad(fromAdjustedHour(hour), 2)}
      <span className="seconds">:00</span>
    </div>
  ));

  return (
    <div className="timestamps" style={{ height, paddingTop: topOffsetPx }}>
      {timestamps}
    </div>
  );
};

export default ScheduleDayTimestamps;
